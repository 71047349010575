<template>
    <div class="row margin-0 bg-white justify-content-center">
        <div class="col-11 pad-15">
            <h4>Amazon Deals</h4>
            <hr>
            <div class="row margin-0">
                <div class="col-12">
                    <a href="https://www.amazon.in?&linkCode=ll2&tag=ebechana0b-21&linkId=a863df83c6e0e76c84d5f7872e085486&ref_=as_li_ss_tl" target="_blank" rel="noopener noreferrer">Shop on Amazon</a>
                </div>
                <div class="col-12">
                    <a href="https://www.amazon.in/tryAB?tag=ebechana0b-21" target="_blank" rel="noopener noreferrer">Shop on Amazon Business</a>
                </div>
                <template v-for="(link, index) in links">
                    <div class="col-md-2 col-6 pad-15" v-html="link" :key="index"></div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            links: [
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B07HGGYWL6&asins=B07HGGYWL6&linkId=fa8320dad12168be7a2ae494bff0acef&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B08XJG8MQM&asins=B08XJG8MQM&linkId=89f38362df54d794da86f095595a6864&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B07Z89SKHT&asins=B07Z89SKHT&linkId=7584c55ed6d3ea4782c780d9a832a1de&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B07HGH8D2R&asins=B07HGH8D2R&linkId=d50ef5cc07b672c9f20e977014e24cdc&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B086171VYS&asins=B086171VYS&linkId=6206bd5155d3e714488b6f95436492d9&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B0837TRT3Z&asins=B0837TRT3Z&linkId=58a8c238703851d03da6149e8745c4d3&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&marketplace=amazon&region=IN&placement=B08XBZBFCQ&asins=B08XBZBFCQ&linkId=9e923d682622928e915aa7b8799cc59b&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&language=en_IN&marketplace=amazon&region=IN&placement=B08R7QTPKB&asins=B08R7QTPKB&linkId=a0da6b1f29de78235b91f724cb93138c&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&language=en_IN&marketplace=amazon&region=IN&placement=B08R7RPVJF&asins=B08R7RPVJF&linkId=584325076577910b7f12993decbeebaa&show_border=true&link_opens_in_new_window=true"></iframe>`,
                `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=ebechana0b-21&language=en_IN&marketplace=amazon&region=IN&placement=B08N5W4NNB&asins=B08N5W4NNB&linkId=3e8bd2b6e7f96a1465d71f7d888308f9&show_border=true&link_opens_in_new_window=true"></iframe>`
            ]
        }
    }
}
</script>
<style lang="stylus" scoped>
.bg-white
    background-color #fff
    min-height 100vh
</style>